import $ from 'jquery'
import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import bootstrap5Plugin from '@fullcalendar/bootstrap5'

function renderCalendar (calendarElement, calendarEvents) {
  const calendar = new Calendar(calendarElement, {
    initialView: 'dayGridMonth',
    plugins: [dayGridPlugin, bootstrap5Plugin],
    themeSystem: 'bootstrap5',
    firstDay: 1,
    height: 'auto',
    displayEventTime: true,
    displayEventEnd: true,
    eventDidMount: ({ event, el }) => {
      $(el).tooltip({
        title: event.title,
        placement: 'top',
        trigger: 'hover click',
        delay: { show: 500, hide: 100 },
      })
    },
    eventTimeFormat: {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    },
    headerToolbar: {
      left: 'prev,next',
      center: 'title',
      right: 'today',
    },
    events: calendarEvents,
  })

  calendar.render()

  $(calendarElement).find('.fc-prev-button').html('<i class="fa fa-chevron-left"></i>')
  $(calendarElement).find('.fc-next-button').html('<i class="fa fa-chevron-right"></i>')
}

$(document).ready(() => {
  $('#calendar-schedule').each(function () {
    const ajaxUrl = $(this).data('ajax-url')

    $.ajax(
      {
        type: 'GET',
        url: ajaxUrl,
        context: $(this)[0],
        processData: false,
        contentType: false,
        dataType: 'json',
        success: data => renderCalendar(this, data?.calendarEvents),
      }
    )
  })
})
