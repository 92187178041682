import styles from './main.scss' // eslint-disable-line no-unused-vars

import $ from 'jquery'
import 'jquery.dirty'
import 'flot'
import { Dropdown, Alert, Modal } from 'bootstrap' // eslint-disable-line no-unused-vars
import dt from 'datatables.net' // eslint-disable-line no-unused-vars
import boot from 'datatables.net-bs5' // eslint-disable-line no-unused-vars
import 'datatables.net-bs5/css/dataTables.bootstrap5.css'
import colreorder from 'datatables.net-colreorder' // eslint-disable-line no-unused-vars
import colreorderboot from 'datatables.net-colreorder-bs5' // eslint-disable-line no-unused-vars
import 'datatables.net-colreorder-bs5/css/colReorder.bootstrap5.css'
import ResponsiveDt from 'datatables.net-responsive' // eslint-disable-line no-unused-vars
import ResponsiveDtBoot from 'datatables.net-responsive-bs5' // eslint-disable-line no-unused-vars
import 'datatables.net-responsive-bs5/css/responsive.bootstrap5.css'
import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/regular'
import '@fortawesome/fontawesome-free/js/brands'
import io from 'socket.io-client'
import * as Y from 'yjs'
import { TextAreaBinding } from 'y-textarea'

import { addAdditiveFeedbackFunctionality, addDeleteFunctionality, dynamicSizeAccordionItem, dynamicSizeSingleAnswer, fullPointsButton, getNewFeedbackAnswerDropdown, refreshAnswerFeedback, refreshGeneralFeedback, loadConstraints, recheckConstraints, renderSourceMissingDeductionResult } from './correction_forms.js'
import { setupGradesCalculator } from './grade_management.js'
import { getUploadedFileHtml } from './html_elements.js'

// Function to enable AJAX on submit of a Form
function enableFormAJAX (classSearch, parent = null) {
  let forms // Needed to enable selecting a class only within its parent
  if (parent) {
    forms = parent.find('form.' + classSearch)
  } else {
    forms = $('form.' + classSearch)
  }
  forms.off('submit')
  forms.dirty({ preventLeaving: true }) // Prevents from leaving the page without saving
  forms.on('submit', function (e) {
    $.ajax(
      {
        type: 'POST',
        url: $(this).attr('action') + '?json', // Needed to enable api functions
        data: new FormData(this),
        context: $(this),
        processData: false,
        contentType: false,
        dataType: 'json',
        success: function (data) {
          // First present the general alerts
          if ('alerts_list' in data) {
            const elementAlert = $($(this).data('alerts') ? $(this).data('alerts') : '#alerts') // The element for adding alerts
            elementAlert.empty() // Remove old alerts for convenience
            data.alerts_list.forEach(item => elementAlert.append('<div class="alert alert-' + ('type' in item ? item.type : 'primary') + ' d-flex align-items-center alert-dismissible fade show" role="alert">' + ('icon' in item ? '<i class="me-2 fa fa-' + item.icon + ' "></i>' : '') + '<div>' + item.content + '</div> <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'))
          }

          if (!data.success) { // Only without success are we having error messages and form is set to invalid
            if ('errors' in data) {
              $(this).find(':input').each(function (index) {
                if (this.id in data.errors) { // If an error occurs, it is marked as invalid, and the error message is added. Otherwise, it is removed and marked as valid.
                  $(this).addClass('is-invalid')
                  $(this).removeClass('is-valid')
                  $(this).parent().children('.invalid-feedback').html(data.errors[this.id].join('<div></div>'))
                } else {
                  $(this).addClass('is-valid')
                  $(this).removeClass('is-invalid')
                  $(this).parent().children('.invalid-feedback').empty()
                }
              })
            }
          } else { // If successful we remove the markers to be prepared for the next try
            $(this).find(':input').each(function (index) {
              $(this).removeClass('is-valid')
              $(this).removeClass('is-invalid')
              $(this).parent().children('.invalid-feedback').empty()
            })
            if ('data' in data) { // Adapt data if new data could not be changed because of permissions
              $(this).find(':input').each(function (index) {
                if ($(this).attr('type') === 'checkbox' || $(this).attr('type') === 'radio') {
                  // Needed as checkboxes have a different behavior
                  if (data.data[this.id] || data.data[$(this).attr('name')] === $(this).val()) {
                    $(this).attr('checked', 'checked')
                    $(this).prop('checked', true)
                  } else {
                    $(this).removeAttr('checked')
                    $(this).prop('checked', false)
                  }
                } else { // Add data as send using json to the input form.
                  if (this.id in data.data) {
                    $(this).val(data.data[this.id])
                  }
                }
              })
            }

            if ('uploaded_files' in data) {
              const userId = $(this).find('#id').val()
              const taskId = $(this).parent().attr('id')
              const fileUploadContainer = $(`#fileupload-${taskId}-${userId}`)

              if (!fileUploadContainer.length) return

              const baseDownloadUrl = fileUploadContainer.data('download-url')
              const baseDeleteUrl = fileUploadContainer.data('delete-url')

              const alreadyUploadedFiles = fileUploadContainer.find('.row').toArray().map(el => el.id)

              for (const [filename, originalFilename] of Object.entries(data.uploaded_files)) {
                if (alreadyUploadedFiles.includes(filename)) return

                const downloadUrl = baseDownloadUrl.replace('FILENAME', filename)
                const deleteUrl = baseDeleteUrl.replace('FILENAME', filename)
                fileUploadContainer.append(getUploadedFileHtml(originalFilename, downloadUrl, deleteUrl))
              }
            }
          }
          // Required for the search function in the user table when setting teams
          if ('team_number' in data) {
            $(this).find('.team_number').each(function () {
              $(this).html(data.team_number) // Needed to enable the search function correctly
            })
          }
          // Required for parsing multiple choice questions
          if ('multiple_choice_data' in data) {
            if (data.multiple_choice_data.status !== 0) {
              const formWrapper = $('#form-wrapper-' + data.multiple_choice_data.task_id)
              formWrapper.removeClass('border-warning')
              if (data.multiple_choice_data.possible_credits === data.multiple_choice_data.credits) {
                formWrapper.addClass('border-success')
              } else {
                formWrapper.addClass('border-danger')
              }
              $('#credits-' + data.multiple_choice_data.task_id).text(parseFloat(data.multiple_choice_data.credits).toFixed(2))
              $('#possible-credits-' + data.multiple_choice_data.task_id).text('/' + parseFloat(data.multiple_choice_data.possible_credits).toFixed(2))
            }
            $('#attempts-' + data.multiple_choice_data.task_id).text(data.multiple_choice_data.attempts)
            // Is the same for all options, therefore defined once
            let correctString = 'text-bg-danger'
            if (data.multiple_choice_data.correct) {
              correctString = 'text-bg-success'
            }

            $(this).find('input[id^="options-"]').each(function (index, element) {
              const optionId = $(element).val()

              // Check if it was checked in the answer string of the current attempt.
              let checkString = ''
              if (optionId in data.multiple_choice_data.answer_json[data.multiple_choice_data.attempts]) {
                checkString = 'check-'
              }

              // Adding the icons for the current attempt
              $(element).before("<span class='fa-regular fa-" + checkString + 'square ' + correctString + "'></span>")

              // Only needed for the sample solution
              if (data.multiple_choice_data.status !== 0) {
                let currentOption = null
                data.multiple_choice_data.options.forEach(function (currentObject) {
                  if (currentObject.id === optionId) {
                    currentOption = currentObject
                  }
                })
                checkString = ''
                if (currentOption.option_type === 'CORRECT') {
                  checkString = 'check-'
                }
                $(element).before("<span class='fa-regular fa-" + checkString + "square text-bg-warning'></span>")
                $(element).parent().find('.mc-option-comment').html(currentOption.comment)
                $(element).parent().find('.mc-option-comment').addClass('p-3 border')
              }
            })
          }

          // This makes sure that elements such as changed border color and credits are adapted after submitting free text
          // answers or corrections.
          if ('free_text_data' in data) {
            const accordionCreditField = $('#accordioncredits-' + data.free_text_data.task_id + '-' + data.free_text_data.user_id)
            if ($(accordionCreditField).length) {
              accordionCreditField.text(parseFloat(data.free_text_data.credits).toFixed(2))
            }

            const accordionSourceMissingField = $('#source-missing-' + data.free_text_data.answer_id)
            if (accordionSourceMissingField) {
              if (data.free_text_data.source_missing) {
                accordionSourceMissingField.html('<i class="fa-solid fa-clipboard-question" data-toggle="tooltip"' +
                    ' title="source missing"></i>')
              } else {
                accordionSourceMissingField.html('')
              }
            }

            const correctionForm = $('#correction-form-' + data.free_text_data.answer_id)
            renderSourceMissingDeductionResult(correctionForm, data.free_text_data.credits)

            recheckConstraints(data.free_text_data.correctors_comment, $('#accordion-item-' + data.free_text_data.answer_id))

            const frameField = $('#collapse-' + data.free_text_data.answer_id)
            if (frameField) {
              frameField.removeClass('border border-4 border-primary border-info border-warning')
              switch (data.free_text_data.status) {
                case 'NOT_STARTED':
                  frameField.addClass('border border-4 border-primary')
                  break
                case 'SUPERVISOR_NEEDED':
                  frameField.addClass('border border-4 border-warning')
                  break
                case 'FIRST_PASS_DONE':
                  frameField.addClass('border border-4 border-info')
                  break
                default:
                  break
              }
            }

            // Adapts the color accordingly after submitting a correction for the accordion header
            const accordionField = $('#heading-accordion-' + data.free_text_data.answer_id + ' .accordion-button')
            if (accordionField) {
              accordionField.removeClass('text-bg-primary text-bg-info text-bg-warning text-bg-success')
              switch (data.free_text_data.status) {
                case 'NOT_STARTED':
                  accordionField.addClass('text-bg-primary')
                  break
                case 'SUPERVISOR_NEEDED':
                  accordionField.addClass('text-bg-warning')
                  break
                case 'FIRST_PASS_DONE':
                  accordionField.addClass('text-bg-info')
                  break
                default:
                  accordionField.addClass('text-bg-success')
              }
            }
          }

          if ('feedback_dict' in data) {
            const feedbackOverview = $('#feedback-overview-' + data.free_text_data.task_id)
            refreshGeneralFeedback($(feedbackOverview), data.feedback_dict)
            const newDropDownHtml = getNewFeedbackAnswerDropdown(data.free_text_data.task_id)
            $('.feedback-dropdown-menu-' + data.free_text_data.task_id).each(function () {
              refreshAnswerFeedback($(this), newDropDownHtml)
            })
          }

          if (data?.success && data?.data?.color_scheme && !window.location.pathname.includes(data?.data?.id)) {
            const colorScheme = data.data.color_scheme
            const currentColorScheme = $('html').attr('data-color-scheme')

            if (colorScheme !== currentColorScheme) {
              $('html').attr('data-color-scheme', colorScheme)
            }
          }
        },
        error: function (xhr, status, error) {
          // Adding an Alert when the server shows an error
          const err = JSON.parse(xhr.responseText) // Get the correct formatted error message
          const elementAlert = $($(this).data('alerts') ? $(this).data('alerts') : '#alerts')
          elementAlert.empty() // Remove old alerts for convenience
          if ('status_code' in err) {
            elementAlert.append('<div class="alert alert-' + err.status_color + ' d-flex align-items-center alert-dismissible fade show" role="alert"> <i class="me-2 fa fa-' + err.status_icon + '"></i><div>' + err.status_title + ' : ' + err.status_message + '</div> <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>')
          } else {
            elementAlert.append('<div class="alert alert-danger d-flex align-items-center alert-dismissible fade show" role="alert"><i class="me-2 fa fa-exclamation-triangle"></i><div>Server returned internal Error state, please retry later. Data could probably not be saved.</div> <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>')
          }
        },
        complete: function () {
          $(this).children().last().remove() // Remove the spinner again
        },
        beforeSend: function (xhr, settings) {
          $(this).append('<div class="spinner-border text-primary" role="status">\n' +
                        '  <span class="sr-only">Loading...</span>\n' +
                        '</div>')
          if (!/^(GET|HEAD|OPTIONS|TRACE)$/i.test(settings.type) && !this.crossDomain) {
            xhr.setRequestHeader('X-CSRFToken', $(this).data('csrf-token'))
          }
        },
      }
    )
    e.preventDefault() // block the traditional submission mechanism
  })
}

// Custom code comes here, starting with code for AJAX with forms
$(document).ready(function () {
  // First for all forms the submit function and handling
  enableFormAJAX('early') // to ensure that datatable forms are not loaded first

  // Second choose all about the tables
  $('table').each(function () {
    // Ensures that only datatables are transformed and not all as all data tables need a provider for data
    if ($(this).data('provider')) {
      const tableData = $('#' + $(this).data('provider'))

      const table = $(this).DataTable({
        colReorder: true,
        responsive: true,
        paging: tableData.data('paging'),
        searching: tableData.data('search'),
        ajax: {
          url: tableData.data('ajax-url'),
          complete: function () {
            if (tableData.data('form-class')) {
              enableFormAJAX(tableData.data('form-class'))
            }
          },
        },
        columns: tableData.data('columns'),
        language: {
          url: tableData.data('language'),
        },
        drawCallback: function () {
          if (tableData.data('form-class')) {
            enableFormAJAX(tableData.data('form-class'))
          }
        },
        buttons: [
          {
            text: 'Reload',
            action: function (e, dt, node, config) {
              dt.ajax.reload()
            },
          },
        ],
      })

      table.on('responsive-display', function (e, datatable, row, showHide, update) {
        if (showHide) {
          if (tableData.data('form-class')) {
            enableFormAJAX(tableData.data('form-class'), row.child())
          }
        }
        // we only want to do something when the collapsed row in responsive view is visible
      })

      table.on('responsive-resize', function (e, datatable, rowsAreShown) {
        if (rowsAreShown.length === 0) return

        if (rowsAreShown.some(rowIsShown => !rowIsShown)) {
          if ($(table.cell(0, 0).node()).attr('tabindex') !== '0') {
            table.column(0).nodes().each((node) => $(node).attr('tabindex', '0')
              .attr('onkeyup', 'if(event.key === "Enter") { event.stopImmediatePropagation(); $(this).click(); }')
            )
          }
        } else {
          if ($(table.cell(0, 0).node()).attr('tabindex') === '0') {
            table.column(0).nodes().each((node) => $(node).removeAttr('tabindex').removeAttr('onkeyup'))
          }
        }
      })
    }
  })

  // Function calls for free text editors
  const freeTextEditors = document.getElementsByClassName('free-text-task-editor')
  for (const item of freeTextEditors) {
    initExercise(item, item.dataset.taskid, item.dataset.initialanswer, item.dataset.lab)
  }

  $('.feedback-overview').each(function () {
    addDeleteFunctionality($(this))
  })

  // adding functionality to elements in correction form
  $('.correction-form').each(function () {
    fullPointsButton($(this))
    dynamicSizeSingleAnswer($(this))
    addAdditiveFeedbackFunctionality($(this))
  })

  $('.accordion-item').each(function () {
    dynamicSizeAccordionItem($(this))
    loadConstraints($(this))
  })

  $('#course-grade-calculate-export').each(function () {
    setupGradesCalculator($(this))
  })

  // changes bootstrap theme based on color scheme and user's browser preference
  const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
  const mediaQueryCallback = (e) => $('html').attr('data-bs-theme', e.matches ? 'dark' : 'light')

  if ($('html').attr('data-color-scheme') === 'auto') {
    $('html').attr('data-bs-theme', darkModeMediaQuery.matches ? 'dark' : 'light')
    darkModeMediaQuery.addListener(mediaQueryCallback)
  }

  const colorSchemeObserver = new MutationObserver(mutationsList => { // eslint-disable-line no-undef
    for (const mutation of mutationsList) {
      if (mutation.type === 'attributes' && mutation.attributeName === 'data-color-scheme') {
        const colorScheme = $('html').attr('data-color-scheme')

        if (mutation.oldValue === 'auto') {
          darkModeMediaQuery.removeListener(mediaQueryCallback)
        }

        if (colorScheme === 'auto') {
          $('html').attr('data-bs-theme', darkModeMediaQuery.matches ? 'dark' : 'light')
          darkModeMediaQuery.addListener(mediaQueryCallback)
        } else {
          $('html').attr('data-bs-theme', colorScheme)
        }
      }
    }
  })

  colorSchemeObserver.observe(document.documentElement, { attributes: true })
})

let ydoc = null
let socket = null
const taskElements = {}

// this function is called by the first editor to initialize the websocket client
function initWebsocket (taskid, lab) {
  socket = io()
  ydoc = new Y.Doc()

  socket.on('update_answer', (data) => {
    const update = new Uint8Array(data.update)
    Y.applyUpdate(ydoc, update)
  })

  socket.on('activate', (data) => {
    const task = data.task
    const duplicates = taskElements[task][8]
    duplicates.forEach((taskid) => {
      const [answerField, fileField, editButton, submitButton, resetButton, modalInstance, todoButton, doneButton, duplicates] = taskElements[taskid] // eslint-disable-line no-unused-vars
      answerField.disabled = false
      if (fileField) {
        fileField.disabled = false
      }
      editButton.hidden = true
      submitButton.hidden = false
      resetButton.hidden = false
    })
  })

  socket.on('deactivate', (data) => {
    const task = data.task
    const duplicates = taskElements[task][8]
    duplicates.forEach((taskid) => {
      const [answerField, fileField, editButton, submitButton, resetButton, modalInstance, todoButton, doneButton, duplicates] = taskElements[taskid] // eslint-disable-line no-unused-vars
      answerField.disabled = true
      if (fileField) {
        fileField.disabled = true
      }
      editButton.hidden = false
      submitButton.hidden = true
      resetButton.hidden = true

      if (todoButton.hidden && doneButton.hidden && answerField.value) {
        todoButton.hidden = false
      }
    })
  })

  socket.on('visible', (data) => {
    const task = data.task
    const duplicates = taskElements[task][8]
    duplicates.forEach((taskid) => {
      const [answerField, fileField, editButton, submitButton, resetButton, modalInstance, todoButton, doneButton, duplicates] = taskElements[taskid] // eslint-disable-line no-unused-vars
      editButton.hidden = true
      submitButton.hidden = true
      resetButton.hidden = true
      answerField.disabled = true
    })
  })

  socket.on('show_lock', (data) => {
    const task = data.task
    const duplicates = taskElements[task][8]
    duplicates.forEach((taskid) => {
      const [answerField, fileField, editButton, submitButton, resetButton, modalInstance, todoButton, doneButton, duplicates] = taskElements[taskid] // eslint-disable-line no-unused-vars
      modalInstance.show()
    })
  })
}

export function initExercise (item, taskid, initialAnswer, lab) {
  if (ydoc == null) {
    initWebsocket(taskid, lab)
  }

  socket.emit('init', { taskid, lab })
  const ytext = ydoc.getText(taskid)

  // if elements occur multiple times in the document they get added to a list of duplicates
  let numberedTaskid = taskid
  if (taskElements[taskid] != null) {
    const [answerField, fileField, editButton, submitButton, resetButton, modalInstance, todoButton, doneButton, duplicates] = taskElements[taskid] // eslint-disable-line no-unused-vars
    numberedTaskid = '' + taskid + '_' + duplicates.length
    duplicates[duplicates.length] = numberedTaskid
  }
  const formArea = item

  const answerField = formArea.querySelector('[name="answer"]')
  answerField.disabled = true
  const fileField = formArea.querySelector('[name="file_upload"]')
  if (fileField) {
    fileField.disabled = true
  }
  const AreaBinding = new TextAreaBinding(ytext, answerField) // eslint-disable-line no-unused-vars

  if (initialAnswer !== 'None') {
    answerField.value = initialAnswer
  }

  let answerChanged = false
  answerField.addEventListener('input', (event) => {
    if (!answerChanged) {
      answerChanged = true
      setTimeout(changeAnswer, 500)
    }
  })
  function changeAnswer () {
    const update = Y.encodeStateAsUpdate(ydoc)
    socket.emit('answer_changed', { taskid, lab, update })
    answerChanged = false
  }

  const editButton = formArea.querySelector('#edit_button')
  editButton.addEventListener('click', (event) => {
    socket.emit('start_editing', { taskid, confirmed: false, answer: answerField.value, alttask: numberedTaskid })
  })

  const todoButton = formArea.querySelector('#todo_button')
  todoButton.addEventListener('click', (event) => {
    socket.emit('mark_task', { taskid, todo: true })
  })

  const doneButton = formArea.querySelector('#done_button')
  doneButton.addEventListener('click', (event) => {
    socket.emit('mark_task', { taskid, todo: false })
  })

  const submitButton = formArea.querySelector('#submit_button')
  submitButton.hidden = true
  submitButton.addEventListener('click', (event) => {
    socket.emit('stop_editing', { taskid })
  })

  const resetButton = formArea.querySelector('#reset_button')
  resetButton.hidden = true
  resetButton.addEventListener('click', (event) => {
    socket.emit('stop_editing', { taskid })
    socket.emit('reset', { taskid, lab })
  })

  socket.on('set_answer_' + taskid, (data) => {
    ytext.delete(0, ytext.length)
    ytext.insert(0, data.answer)
    changeAnswer()
    answerField.value = data.answer
  })

  // the functionality surrounding the modal that informs the user about the lock on another taskid
  const modal = formArea.querySelector('#exercise-warning')
  const modalInstance = new Modal(modal)
  taskElements[numberedTaskid] = [answerField, fileField, editButton, submitButton, resetButton, modalInstance, todoButton, doneButton, [taskid]]

  const continueButton = modal.querySelector('#continue-button')
  continueButton.addEventListener('click', (event) => {
    socket.emit('start_editing', { taskid, confirmed: true, answer: answerField.value })
  })

  if (initialAnswer && todoButton.hidden && doneButton.hidden) {
    todoButton.hidden = false
  }

  if (!doneButton.hidden) {
    answerField.classList.add('text-bg-warning-subtle')
  }

  socket.on('show_done', (data) => {
    const task = data.task
    const duplicates = taskElements[task][8]
    duplicates.forEach((taskid) => {
      const [answerField, fileField, editButton, submitButton, resetButton, modalInstance, todoButton, doneButton, duplicates] = taskElements[taskid] // eslint-disable-line no-unused-vars
      todoButton.hidden = true
      doneButton.hidden = false
      answerField.classList.add('text-bg-warning-subtle')
    })
  })

  socket.on('show_todo', (data) => {
    const task = data.task
    const duplicates = taskElements[task][8]
    duplicates.forEach((taskid) => {
      const [answerField, fileField, editButton, submitButton, resetButton, modalInstance, todoButton, doneButton, duplicates] = taskElements[taskid] // eslint-disable-line no-unused-vars
      todoButton.hidden = false
      doneButton.hidden = true
      answerField.classList.remove('text-bg-warning-subtle')
    })
  })
}
